@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@700&display=swap');
body {
  font-family: 'Space Mono', monospace;
  background: #001628;
  color: #00c4ff;
  letter-spacing: .025rem !important;
}

body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}

.decoration-none,
.decoration-none:hover {
  text-decoration: none;
}

.outline-none {
  outline: none !important;
}

.hoverable {
  transition: .3s cubic-bezier(.25,.8,.5,1);
}

.hoverable:hover {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

a.text-light:focus, a.text-light:hover {
  color: #fff !important;
}

.page-link {
  color: #fff;
  background-color: #424242;
  border-color: #424242;
}

.page-item.active > .page-link,
.page-item.disabled > .page-link {
  color: #fff;
  background-color: #0156B3;
  border-color:#0156B3;
  pointer-events: none;
  cursor: auto;
}

.spinner-inner {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.spinner-circle {
  color: #00c4ff;
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-line {
  animation: atom-spinner-animation-1 s linear infinite;
  transform-origin: 50% 50%;
  position: absolute;
  border: 3px solid #00c4ff;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.spinner-line:nth-child(1) {
  animation: atom-spinner-animation-1 1s linear infinite;
  transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}

.spinner-line:nth-child(2) {
  animation: atom-spinner-animation-2 1s linear infinite;
  transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}

.spinner-line:nth-child(3) {
  animation: atom-spinner-animation-3 1s linear infinite;
  transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@keyframes atom-spinner-animation-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes atom-spinner-animation-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}

.image-sizer {
  transition: padding-bottom .2s cubic-bezier(.25,.8,.5,1);
}

.image-preload {
  filter: blur(2px);
}

.image, .image-placeholder {
  z-index: -1;
  top: 0;
  left: 0;
  background-repeat: no-repeat !important;
}

.image-cover {
  background-size: cover !important;
}

.image-contain {
  background-size: contain !important;
}
